import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import demoImgCrop from '../Assets/img/cropDemo.jpeg'
import { Link } from 'react-router-dom'


const MustOrderHome = () => {

    const { data, error, isLoaded } = GetRequest("/Recommendation/GetListOfMustOrderProducts");

    if (error !== null) {
        return <Error data="Must To Order " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    if (data.result.length !== 0) {
        return (

            <div className="col-md-6">

                <div className="card card-success card-tabs">
                    <div className="card-header">
                        <h3 className="card-title">Products You must have in Inventory </h3>
                    </div>
                    <div className="card-body no-padding">

                        {
                            data.result && data.result.map((item, index) => (
                                <div className="callout callout-danger" id={item.productCategoryId}>
                                    <div className="row">
                                        <div className="col-4 centerImage ">
                                            {item.productSmallImage == null ? <i className="fa fa-file-image-o dumProdBigIcon"></i> :
                                                <img className="ProductsInfoHeadImg" src={item.productSmallImage} alt="Crop image" />}
                                        </div>
                                        <div className="col-8 ">
                                            <b>{item.productLineName}</b> <br />  {item.recommendationTitle}<br />
                                            {/* <b>Crop Recommended on -  </b> {item.cropName} ({item.productCategoryName}) */}
                                        </div>
                                    </div>
                                    <div className='dtsPr'>
                                        <br />
                                        <b>  Recommended By - </b> {item.recommendationRecommendedBy}  <br />
                                        {item.recommendationDetails}  <br />
                                        <b>   Suggestion -</b> {item.suggestion} </div>
                                </div>

                            ))
                        }
                    </div>
                    <div className="card-footer">
                        <Link to="/NewOrder"> <button type="button" className="btn btn-success btn-block"><i className="fa fa-plus"></i> Place New Order</button></Link>
                    </div>
                </div>
            </div>
        )


    } else {
        return (
            <div className="col-md-6">

                <div className="card card-danger card-tabs">
                    <div className="card-header">
                        <h3 className="card-title">Booster Recommendations</h3>
                    </div>
                    <div className="card-body">
                        <table className="table table-sm">
                            <tbody>
                                <tr>
                                    <td className="NoDataAvailableText"> No Data Available </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )

    }
}

export default MustOrderHome