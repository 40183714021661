import React from 'react';
import GetRequest from '../Services/APIRequest';

const TotalCreditAmountAddFYBox = () => {
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');

  const { data } = GetRequest(`/Account/GetTotalCreditNotesAmountAddedInFinancialYear/${distID}`);

  // Check if data and data.result are properly defined
  const boosterAmount = data?.result?.boosterAmount;
  const parisAmount = data?.result?.parisAmount;

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);

  if (boosterAmount !== undefined && parisAmount !== undefined) {
    return (
      <div className="row">
        <div className="col-lg-3 col-6">
          <div className="small-box bg-success">
            <div className="inner">
              <h4>{formatCurrency(boosterAmount)}</h4>
              <p className="smallfontindicator">Booster Credit Notes Added In Year</p>
            </div>
            <div className="icon">
              <i className="fa fa-inr"></i>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className="small-box bg-confirmed">
            <div className="inner">
              <h4>{formatCurrency(parisAmount)}</h4>
              <p className="smallfontindicator">Paris Credit Notes Added In Year</p>
            </div>
            <div className="icon">
              <i className="fa fa-inr"></i>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>; // Render nothing if data is not available
  }
};

export default TotalCreditAmountAddFYBox;
