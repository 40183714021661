import React from 'react'
import { useEffect, useState } from 'react';
import GetSelectOptionList from '../NewPayment/GetSelectOptionList';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import $, { event } from 'jquery';
import { GoBack } from '../Layouts/GoBack';
import PaymentDistributionsEdit from './PaymentDistributionsEdit';

const SubmitPaymentEdit = () => {

    const Id = useParams();
    const [file, setFile] = useState()
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [inputsNewBank, setInputsNewBank] = useState({});
    const [selectVal, setSelectVal] = useState("");
    const [selectFormVal, setSelectFormVal] = useState("");
    const [selectPayModeVal, setSelectPayModeVal] = useState("");
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [updateFormDataPayment, setUpdateFormDataPayment] = useState('');
    const [updateFormDataSchemes, setUpdateFormDataSchemes] = useState('');
    const [updateFormDataCostCenter, setUpdateFormDataCostCenter] = useState('');
    const [fileOrPicture, setFileOrPicture] = useState();
    const [subValidMsg, setSubValidMsg] = useState();

    const getPaymentDetailsForUpdate = () => {
        axios.get(`https://dpapi.boosterparis.in/api/payment/GetPaymentDetailsForEdit/${Id.Id}`, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setUpdateFormDataPayment(response.data.result.paymentGeneralDetailsDTOForEdit)
                    setUpdateFormDataSchemes(response.data.result.schemes);
                    setUpdateFormDataCostCenter(response.data.result.outStandingPaymentDTO);
                    updateSchemesDataLoop(response.data.result.schemes);
                    updateOutStandDataLoop(response.data.result.outStandingPaymentDTO);
                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }

    useEffect(() => {
        setTimeout(() => {
            getPaymentDetailsForUpdate();
        }, 500);

    }, []);

    const notifySuccessEdit = () => toast.success('Your Payment Edit Successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });

    const notifySuccess = () => toast.success('Your New Bank Account Added Successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccessParis = () => toast.success('Paris company selected for making payments!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccessBooster = () => toast.success('Booster company selected for making payments!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });

    function handleChangeFile(event) {
        setFile(event.target.files[0])
        const name = event.target.name;
        const value = event.target.files[0];
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleChangeNewBank = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputsNewBank(values => ({ ...values, [name]: value }))
    }

    const handleSelectChange = (event) => {
        setSelectVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleSelectChangeBankId = (event) => {
        setSelectVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputsNewBank(values => ({ ...values, [name]: value }))
    }
    const handleSelectFormChange = (event) => {
        setSelectFormVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handlePayModeChange = (event) => {
        setSelectPayModeVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleDateFormatChange = (event) => {
        const fomDate = event.target.value;
        const fomDateloop = fomDate.split('-');
        const dd = fomDateloop[2];
        const mm = fomDateloop[1];
        const yy = fomDateloop[0];
        const formatOk = `${dd}/${mm}/${yy}`;
        const name = event.target.name;
        setInputs(values => ({ ...values, [name]: formatOk }))
    }



    const handleUpdatePayment = (event) => {
        event.preventDefault();
        const forEditData = {};

        forEditData.transactionBy = '';
        //forEditData.transactionDate= document.getElementById('transactionDateId').value;
        forEditData.transactionDate = '';
        forEditData.InstrumentDate = '';
        forEditData.paymentMode = document.getElementById('paymode').value;
        forEditData.toBankId = parseInt(document.getElementById('selbankid').value);
        forEditData.fromBankId = 0;
        forEditData.details = document.getElementById('detailsId').value;
        forEditData.amountPaid = parseInt(document.getElementById('amountPaindId').value);
        forEditData.transactionId = document.getElementById('transactionIdVal').value;
        forEditData.SalesTeam_HandoverDetails = '';
        forEditData.paymentId = parseInt(document.getElementById('paymentIdVal').value);
        forEditData.TotalPayment = document.getElementById('amountPaindId').value;
        forEditData.schemeDistributionDetails = [];
        forEditData.costCentreWisePaymentDistribution = [];

        var childInput = document.querySelector('#collapseAgainsOut');
        var getdata = childInput.querySelectorAll('input');
        getdata.forEach((obj) => {
            var costData = {};
            costData.costCentreId = obj.id;
            costData.paymentId = parseInt(document.getElementById('paymentIdVal').value);
            costData.paidAmountAgainstCostCentre = obj.value;
            costData.comments = '';
            forEditData.costCentreWisePaymentDistribution.push(costData);
            console.log(costData)
        });



        axios.post("https://dpapi.boosterparis.in/api/Payment/SubmitPaymentForEdit2", forEditData, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.data.isSuccess === true) {
                    notifySuccessEdit();
                    navigate(`/MyPayments/PaymentDetails/${response.data.result.paymentId}`);
                }
                else {
                    setSubValidMsg(response.data.message);
                    document.getElementById('clickSubmitValidError').classList.remove('hide');
                }

            }).catch((response) => {

                console.log(response.statusText);
            });

    }


    const handleAddDistNewBankAccount = (event) => {
        event.preventDefault();

        axios.post("https://dpapi.boosterparis.in/api/Bank/AddDistributorBankAccount", inputsNewBank, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    notifySuccess();
                    $('#AddDistNewBankAccountModal').modal('hide');
                    $('#AddDistNewBankAccountModal').removeAttr('style');
                    $('#closeAddNewAccModal').trigger('click');
                    return <GetSelectOptionList name={`GetDistributorBanks/${distID}`} />;

                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }

    function closeErrorMsg() {
        document.getElementById('clickSubmitValidError').classList.add('hide');
    }
    $(document).on('change', '.file-input', function () {
        var filesCount = $(this)[0].files.length;
        var textbox = $(this).prev();
        var replicaText = $('.file-input0').prev();
        if (filesCount === 1) {
            var fileName = $(this).val().split('\\').pop();
            textbox.text(fileName);
            replicaText.text(fileName)
        } else {
            textbox.text(filesCount + ' files selected');
            replicaText.text(filesCount + ' files selected');
        }
    });

    setTimeout(function () {
        if (updateFormDataPayment !== '') {
            var vid = updateFormDataPayment.toBankId;
            var pid = updateFormDataPayment.paymentMode;
            $('#selbankid').find('option[id=' + vid + ']').attr('selected', true);
            $('#paymode').find('option[value=' + pid + ']').attr('selected', true)
        }
    }, 1000);


    return (
        <>
            <div className="card">
                <div className="card-body nobornopad">

                    <form onSubmit={handleUpdatePayment} className='master-form edit-master-form' novalidate>
                        <div id="accordion2">
                            <h5 className='payDistHeadTransD' data-bs-toggle="collapse" href={`#collapsePaymentsTrans`} >Payment Transaction Details

                                <a className="btn dp-right-mt5 accUIIcon">
                                    <i className="fa fa-angle-double-down"></i>
                                    <i className="fa fa-angle-double-up hide"></i>
                                </a>
                            </h5>

                            <div className="row collapse" data-bs-parent="#accordion2" id='collapsePaymentsTrans'>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Amount Paid " className="form-label">Amount Paid :</label>
                                        <input type="number" name='amountPaid' id='amountPaindId' className="form-control" placeholder="Amount Paid  ..."
                                            defaultValue={updateFormDataPayment.amountPaid}
                                            onChange={handleChange} required pattern="^(([0-9]*)|(([0-9]*)\.([0-9]*)))$"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Payment Mode " className="form-label">Payment Mode</label>
                                        <select className="form-control" name='paymentMode' required value={selectPayModeVal} onChange={handlePayModeChange} id='paymode'>
                                            <option id="-1"  > Select Payment Mode</option>
                                            <option id="1" value="ChequeDD">Cheque/DD</option>
                                            <option id="2" value="RTGS">RTGS</option>
                                            <option id="3" value="NEFT">NEFT</option>
                                            <option id="4" value="UPI">UPI</option>
                                            <option id="5" value="Cash">Cash</option>
                                            <option id="6" value="Others">Others </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Transaction Id/Cheque# " className="form-label">Transaction Id/Cheque# :</label>
                                        <input type="text" name='transactionId' id="transactionIdVal" required className="form-control" placeholder="Transaction Id/Cheque ..."
                                            defaultValue={updateFormDataPayment.transactionId}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>



                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="To Bank " className="form-label">Booster/Paris Bank Account</label>
                                        <select className="form-control" name='toBankId' required value={selectVal} onChange={handleSelectChange} id="selbankid">
                                            <option value="0"> Select Booster/Paris Bank Account</option>
                                            <GetSelectOptionList name={`GetCompanyBanks/${boostID}`} />

                                        </select>
                                    </div>
                                </div>



                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Details " className="form-label">Details</label>
                                        <textarea name='details' id='detailsId' className="form-control txtDetailsHt" placeholder="Enter Details here ..."
                                            defaultValue={updateFormDataPayment.details}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                {/* <div className="col-sm-4">
                                          <label for="Details " className="form-label">Upload Receipt </label>
                                        <div className="file-drop-area">
                                            <span className="choose-file-button"  >Upload files</span>
                                            <span className="file-message">or drag and drop files here</span>
                                            <input className="file-input" type="file" multiple name="File" onChange={handleChangeFile} />
                                        </div>
                                </div> */}

                            </div>

                        </div>
                        <PaymentDistributionsEdit />

                        <div className="row ">
                            <div className="col-sm-6  card-body">
                                <input type="hidden" name='distributorId' id="localDistId" className="form-control"
                                    value={distID}
                                />
                                <input type="hidden" name='companyId' id="localCompActiveId" className="form-control"
                                    value={boostID} />

                                <input type="hidden" name='paymentIdVal' id="paymentIdVal" className="form-control"
                                    value={updateFormDataPayment.paymentId}
                                />


                                <br />

                                <input type="submit" className="btn btn-success float-right submit-btn" value="Update Payment Details" />


                            </div>
                            <div className="col-sm-6  card-body">
                                <span id="clickSubmitValidError" className='hide'>
                                    <div className="alert alert-danger alert-dismissible">
                                        <button type="button" className="btn-close" onClick={closeErrorMsg} ></button>
                                        <label>{subValidMsg} </label>
                                    </div>
                                </span>
                            </div>
                            <span id="clickSubmitError" className='hide'>
                                <div className="alert alert-danger alert-dismissible">
                                    <button type="button" className="btn-close"  ></button>
                                    <strong>Info!</strong>  “Something went wrong, Please contact administrator.”
                                </div>
                            </span>
                        </div>

                    </form>

                    <div id='loaderFormSubmit' className='col-md-12 hide'>
                        <div id="loaderScreen" ></div>
                    </div>
                    <div className="modal fade" id="UploadPhotoOrTakeCameraPhototModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title"> Upload Receipt Or Take Picture </h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="col-sm-12">
                                        <label for="Details " className="form-label">Upload Receipt </label>
                                        <div className="file-drop-area">
                                            <span className="choose-file-button"  >Upload files</span>
                                            <span className="file-message">or drag and drop files here</span>
                                            <input className="file-input" type="file" multiple name="File" onChange={handleChangeFile} />
                                        </div>
                                    </div>
                                    <div className='orCenterText'><span>OR</span> </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label for="Details " className="form-label">Take Picture</label>
                                            <div>
                                                <button className="btn btn-success mycampbtn"  >Camera</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    <button type="button" className="btn btn-danger btn-100 dp-right" id='closeUploadModal' data-bs-dismiss="modal">Close</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="AddDistNewBankAccountModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title"> Add Your New Bank Account </h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleAddDistNewBankAccount} novalidate>

                                        <div className="mb-3 mt-3">
                                            <label for="BankNameList " className="form-label">Select Bank Name :</label>
                                            <select className="form-control" name='BankId' required value={selectVal} onChange={handleSelectChangeBankId}>
                                                <option value=""> Select Bank Name </option>
                                                <GetSelectOptionList name={`GetBankList`} />
                                            </select>

                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="AccountName " className="form-label">Bank Account Name :</label>
                                            <input type="text" id="AccountName" className="form-control"
                                                placeholder="Enter Bank Name" name="AccountName"
                                                onChange={handleChangeNewBank} />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="AccountNumber " className="form-label">Bank Account Number :</label>
                                            <input type="text" id="qtyControl" className="form-control"
                                                placeholder="Enter Account Number" name="AccountNumber"
                                                onChange={handleChangeNewBank} required pattern="^(([0-9]*)|(([0-9]*)))$" />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for=" BranchName" className="form-label">Branch Name  :</label>
                                            <input type="text" id="qtyControl" className="form-control"
                                                placeholder="Enter Branch Name" name="BranchName"
                                                onChange={handleChangeNewBank} required />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for=" ShortName" className="form-label"> Enter Your Bank Account Short Name:</label>
                                            <input type="text" id="qtyControl" className="form-control"
                                                placeholder="Enter Bank Account Short Name" name="ShortName"
                                                onChange={handleChangeNewBank} />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for=" Ifsccode" className="form-label"> IFSC Code :</label>
                                            <input type="text" id="qtyControl" className="form-control"
                                                placeholder="Enter Ifsc code" name="Ifsccode"
                                                onChange={handleChangeNewBank} required />
                                        </div>
                                        <input className="btn btn-primary btn-120" type="submit" value="Add New Account" />
                                        <button type="button" className="btn btn-danger btn-100 dp-right" id='closeAddNewAccModal' data-bs-dismiss="modal">Close</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
            <GoBack linkdata="/MyPayments/MyPaymentsList" />
        </>
    )
}

function updateSchemesDataLoop(dts) {

}

function updateOutStandDataLoop(dts) {

}

export default SubmitPaymentEdit

