import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'


const EventsHome = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Event/GetListOfCampaignsEventsInDistributorArea/${distID}`);

    if (error !== null) {
        return <Error data={"Campaigns/Events"} />;
    }

    if (!isLoaded) {
        return <Loading />;
    }

    if (data.result.length !== 0) {
        return (

            <div className="col-md-6">
                <div className="card card-primary">
                    <div className="card-header widget-user-header">
                        <h3 className="card-title">Campaigns/Events</h3>
                    </div>
                    <div className="card-body">
                        <div id="accordion">
                            {
                                data.result && data.result.map(item => (
                                    <div className="card  ">
                                        <a className="btn" data-bs-toggle="collapse" href={`#collapse${item.id}`}>
                                            <div className="card-header bg-success text-white">
                                                {item.name}
                                            </div> </a>
                                        <div id={`collapse${item.id}`} className="collapse" data-bs-parent="#accordion">
                                            <div className="card-body">
                                                <img src={item.eventCoverImage} className="ProdimgBannerEvent" />
                                                <h6><img src={item.eventSpeaker1Image} className="ProdimgSmall" />  <span className='spName'> Speaker Name - {item.eventSpeaker1} </span> </h6>
                                                <br /> <br />
                                                {item.details}
                                                <br />
                                                <a href={`${item.link}`} target='_blank'>{item.link}</a>
                                                <br />
                                                <a href='https://dpadmin.boosterparis.in/allevents' target='_blank'>All Events</a>
                                                <a href={`whatsapp://send?text=${item.messageForSharing}`} data-action="share/whatsapp/share" className="botao-wpp-HomeIcon" target="_blank">
                                                    <i className="fa fa-whatsapp whatsappIconShare"></i>
                                                </a> <br />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                {/* <div className='card'>
                        <div className='card-body' id="dashboardVideoSection">
                        <div className="row">
                        <div className="col-md-12">
                            <div className="card card-widget">
                                <div className="card-header">
                                शेतीविषयक व्हिडीओ
                                </div>
                                <div className="card-body">
                                <iframe width="100%" height="315"  src="https://www.youtube.com/embed/cfCI5kLf7Sw" title="खरीपाची पूर्व तयारी. कोणते पिक घ्यावे ?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card card-widget"> 
                                <div className="card-body">
                                        <iframe  width="100%" height="315" src="https://www.youtube.com/embed/zdii82e-97s" title="संपूर्ण तूर व्यवस्थापन - व्हाईटगोल्ड पॅटर्न - गजानन जाधव" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-widget"> 
                                <div className="card-body">
                                <iframe  width="100%" height="315"  src="https://www.youtube.com/embed/uGap3TPSheU" title="सध्याचे प्रश्न व त्यावरील उपाय योजना" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"   allowFullScreen></iframe>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card card-widget"> 
                                <div className="card-body">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/y8L00wR_Uus" title="फ्लॉवर / कोबी संपूर्ण व्यवस्थापन" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-widget"> 
                                <div className="card-body">
                                <iframe width="100%" height="315"src="https://www.youtube.com/embed/bSnZ3KDhQPQ" title="मिरचीचे लागवडी पर्यंतचे  व्यवस्थापन" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"   allowFullScreen></iframe>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card card-widget"> 
                                <div className="card-body">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/q034hSjjg1Y" title="उन्हाळी पिकांचे सध्य परिस्थिती मधील नियोजन" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"   allowFullScreen></iframe>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-widget"> 
                                <div className="card-body">
                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/RCRgG454wsw" title="१६ मार्च २०२४  हवामान अंदाज । #हवामानअंदाज #wetherupdate #agriculture" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"   allowFullScreen></iframe>
                                    

                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card card-widget"> 
                                <div className="card-body">
                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/ok-yUe4ZQo8" title="वादळी पावसाचा कालावधी वाढला । 6 एप्रिल 2024 हवामान अंदाज #हवामानअंदाज #agriculture" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"   allowFullScreen></iframe>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                        </div> */}
            </div >


        )

    } else {

        return (
            <div className="col-md-6">
                <div className="card card-primary">
                    <div className="card-header widget-user-header">
                        <h3 className="card-title">Campaigns/Events</h3>
                    </div>
                    <div className="card-body">
                        <table className="table table-sm">
                            <tbody>
                                <tr>
                                    <td className="NoDataAvailableText"> No Data Available </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )

    }
}


export default EventsHome

