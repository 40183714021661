
import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'

const AccountLedgerList = (props) => {
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const compId = props.compid;

  const { data, error, isLoaded } = GetRequest(`/Account/GetLedger/${distID}/${parseInt(compId)}`);

  if (error !== null) {
    return <Error data=" Account Summary " />;
  }
  if (!isLoaded) {
    return <Loading />;
  } else {
    addFilter();
  }

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);

  function addFilter() {
    setTimeout(function () {
      let list = document.querySelectorAll('.listfilterhead');
      let itemBox = document.querySelectorAll('.itemfiltered');

      for (let i = 0; i < list.length; i++) {
        list[i].addEventListener('click', function () {
          for (let j = 0; j < list.length; j++) {
            list[j].classList.remove('active');
          }
          this.classList.add('active');

          let dataFilter = this.getAttribute('data-filter');

          for (let k = 0; k < itemBox.length; k++) {
            itemBox[k].classList.remove('active');
            itemBox[k].classList.add('hide');

            if (itemBox[k].getAttribute('data-item') == dataFilter || dataFilter == "all") {
              itemBox[k].classList.remove('hide');
              itemBox[k].classList.add('active');
            }
          }
        })
      }
    }, 1000);
  }


  return (
    <div className="col-md-12">
      <div className="card noUI accLedUI">
        <div className="card-header noPad" style={{ display: "flex" }}>
          <button className="form-control flBtn listfilterhead active" data-filter="all"> All </button>
          <button className="form-control flBtn listfilterhead" data-filter="1"> Payment </button>
          <button className="form-control flBtn listfilterhead" data-filter="3"> Cr  </button>
          <button className="form-control flBtn listfilterhead" data-filter="0"> Invoice </button>
          <button className="form-control flBtn listfilterhead" data-filter="2"> Dr  </button>
          <button className="form-control flBtn listfilterhead" data-filter="4"> Journal  </button>

        </div>
        <div className="card-body tbscroll" id="accListUI">
          <div id="accordion" >
            {
              data.result && data.result.map((items, index) => (

                <div className="card itemfiltered" data-item={items.transactionType} id={items.serialNumber}>
                  <div className="card-header" data-bs-toggle="collapse" href={`#collapse${items.serialNumber}`}>
                    <table className='table'>
                      <tr>
                        <td className='w50'>
                          <label className="accHeadName">
                            <span className='sumryTxt'>{items.voucherType} </span> - {items.title}

                          </label>
                        </td>
                        <td className='w50r'>
                          <label className={`accAmountHead ${items.drcstr}`}> {items.drcstr == 'dr' ? '-' : '+'}  {formatCurrency(items.amount)}</label>
                        </td>
                      </tr>
                      <tr>
                        <td className='w50'>
                          <span className='dtSmall'>{items.dateStr}   {items.status !== null && items.status !== "" && items.status !== "Confirmed" ?
                            '[' + items.statusDisplayText + ']' : ''}</span>
                        </td>
                        <td className='w50r'>
                          <span className='dtSmall'>{items.drcstr}</span>
                        </td>
                      </tr>
                    </table>

                  </div>
                  <div id={`collapse${items.serialNumber}`} className="collapse" data-bs-parent="#accordion">
                    <div className="card-body">
                      <label className='sumryTxt' >{items.summary}</label>
                      <Link to={`/CreateQuery?Id=${items.title}&summary=${items.summary}&date=${items.date.slice(0, 10)}`} className='btn btn-danger btn-sm flr'>Raise Query</Link>

                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountLedgerList