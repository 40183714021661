import React from 'react'
import GetRequest from '../Services/APIRequest'

const GetSelectOptionList = (props) => {
    const ulink = props.name;
    const { data, error, isLoaded } = GetRequest(`/Bank/${ulink}`);

    if (data.result != undefined) {
        return (
            <>
                {
                    data.result.map((item, index) => (
                        <option id={item.id} value={item.id} selected={data.result.length === 1}> {item.completeName !== undefined ? item.completeName : item.nameEnglish}</option>
                    ))
                }
            </>
        )
    }
}

export default GetSelectOptionList